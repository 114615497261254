import React, { useEffect } from "react";

const WhatsAppRedirect = () => {
  useEffect(() => {
    window.location.href =
      "https://www.whatsapp.com/channel/0029VaawejrDZ4LSJ6vOb82g";
  }, []);
  return;
};

export default WhatsAppRedirect;
