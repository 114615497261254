import React, { useState } from "react";
import Final from "../Final.png";
import {
  FaLinkedin,
  FaGithub,
  FaTwitter,
  FaEnvelope,
  FaWhatsapp,
  FaInstagram,
  FaYoutube,
} from "react-icons/fa";
import "./Home.css";

const Home = () => {
  // State to toggle the menu visibility
  const [isMenuOpen, setMenuOpen] = useState(false);

  // Toggle menu on hamburger click
  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };

  return (
    <>
      <nav className={`navbar ${isMenuOpen ? "active" : ""}`}>
        <div className="hamburger" onClick={toggleMenu}>
          <div></div>
          <div></div>
          <div></div>
        </div>
        <div className={`nav-links ${isMenuOpen ? "active" : ""}`}>
          {/* Show these links on larger screens */}
          <a href="#home">Home</a>
          <a
            href="https://www.whatsapp.com/channel/0029VaawejrDZ4LSJ6vOb82g"
            target="_blank"
            rel="noopener noreferrer"
            className="nav-link">
            <FaWhatsapp /> WhatsApp
          </a>
          <a
            href="https://www.instagram.com/whatifankit_2.0/"
            target="_blank"
            rel="noopener noreferrer"
            className="nav-link">
            <FaInstagram /> Instagram
          </a>
          <a
            href="https://www.youtube.com/channel/UCNN2It14KxnXUdfi4Wvaw6g"
            target="_blank"
            rel="noopener noreferrer"
            className="nav-link">
            <FaYoutube /> YouTube
          </a>
        </div>
      </nav>

      <div className="home-container">
        <div className="text-section">
          <h1 className="main-heading">Ankit Sharda</h1>
          <p className="subtext">Bringing Revolution</p>
          <div className="social-icons">
            <a
              href="https://www.linkedin.com/in/ankitshardaa/"
              target="_blank"
              rel="noopener noreferrer"
              className="social-icon"
              title="LinkedIn">
              <FaLinkedin />
            </a>

            <a
              href="https://x.com/whatifankit?s=21&t=O8JuEIEtMRvU0LK7mOSQHw"
              target="_blank"
              rel="noopener noreferrer"
              className="social-icon"
              title="Twitter">
              <FaTwitter />
            </a>
            <a
              href="mailto:whatifankit@gmail.com"
              className="social-icon"
              title="Email">
              <FaEnvelope />
            </a>
          </div>
        </div>
        <div className="image-section">
          <img src={Final} alt="Ankit Sharda" className="profile-image" />
        </div>
      </div>
      <footer className="footer">
        <p>© {new Date().getFullYear()} Ankit Sharda. All Rights Reserved.</p>
      </footer>
    </>
  );
};

export default Home;
