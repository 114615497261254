import React, { useEffect } from "react";

const InstagramRedirect = () => {
  useEffect(() => {
    window.location.href = "https://www.instagram.com/whatifankit_2.0/";
  }, []);
  return;
};

export default InstagramRedirect;
