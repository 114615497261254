import React, { useEffect } from "react";

const YouTubeRedirect = () => {
  useEffect(() => {
    window.location.href =
      "https://www.youtube.com/channel/UCNN2It14KxnXUdfi4Wvaw6g";
  }, []);
  return;
};

export default YouTubeRedirect;
