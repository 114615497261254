import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./components/Home";
import InstagramRedirect from "./components/InstagramRedirect";
import YouTubeRedirect from "./components/YouTubeRedirect";
import WhatsAppRedirect from "./components/WhatsAppRedirect";
import Navbar from "./components/Navbar";

function App() {
  return (
    <Router>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/instagram" element={<InstagramRedirect />} />
        <Route path="/youtube" element={<YouTubeRedirect />} />
        <Route path="/whatsapp" element={<WhatsAppRedirect />} />
      </Routes>
    </Router>
  );
}

export default App;
